<template>
    <div>
        <div v-if="userinfo.usertype==18">
            <el-card class="box-card" shadow="always">
                <div style="display: flex;justify-content: center;">
                    <div style="width: 95%;display: flex;justify-content: space-between">
                        <h2 style="font-weight: bolder">实时概况</h2>
                        <el-date-picker
                                @change="getOrderDataInfo"
                                v-model="orderdatatime"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="info-box">
                    <div>
                        <div class="item">站内订单数</div>
                        <div style="font-size: 18px;color: #ff6a57">{{orderData.ordernum}}</div>
                        <div class="item">客单均价($)</div>
                        <div style="font-size: 18px;color: #e83132">{{orderData.useravg}}</div>
                    </div>
                    <div>
                        <div class="item">站内销售额($)</div>
                        <div style="font-size: 18px;color: #ec9217">{{orderData.sales}}(￥{{orderData.sales*6.2}})</div>
                        <div class="item">Netflix续费率</div>
                        <div style="font-size: 18px;color: #10aeb5">{{orderData.netflixrenew}}</div>
                    </div>
                    <div>
                        <div class="item">新增用户</div>
                        <div style="font-size: 18px;color: #545499">{{orderData.newusers}}</div>
                        <div class="item">总用户数</div>
                        <div style="font-size: 18px;color: #4d5054">{{orderData.usersnum}}</div>
                    </div>
                    <div>
                        <div class="item">复购率</div>
                        <div style="font-size: 18px;color: #628b99">{{orderData.renew}}</div>
                        <div class="item">YouTube待处理数据</div>
                        <div style="font-size: 20px;color: red;font-weight: bolder">{{orderData.ytbnum}}</div>
                    </div>
                </div>
            </el-card>
            <el-card shadow="always" style="margin-top: 2em">
                <div style="margin-left: 55%;margin-bottom: 20px;">
                    <span style="font-size: 1.5em">选择筛选时间范围：</span>
                    <el-date-picker
                            @change="getAnalysisData"
                            v-model="orderanalysistime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div ref="work" style="width: 98%;height:300px;"></div>
            </el-card>
        </div>

        <div v-if="userinfo.usertype==21">
            <el-card shadow="always" style="margin-top: 2em">
                <div style="display: flex;justify-content: space-between;margin-bottom: 30px;">
                    <div style="font-size: 24px;font-weight: bolder">
                        <span>总销售额：{{staffstatisdata.totalsales}}($)</span>
                    </div>
                    <div>
                        <span style="font-size: 1.5em">选择筛选时间范围：</span>
                        <el-date-picker
                                @change="getStaffStatisDataInfo"
                                v-model="statistime"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div ref="statis" style="width: 98%;height:300px;"></div>
                <div style="margin-top: 20px;">
                    <el-table
                            :data="staffstatisdata.svos"
                            style="width: 100%">
                        <el-table-column
                                align="center"
                                prop="name"
                                label="用户姓名">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="email"
                                label="用户邮箱">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="price"
                                label="总销售额">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="profit"
                                label="总收益">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="wallet"
                                label="待提现金额">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                prop="withdrawal"
                                label="已提现金额">
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import { getOrderData,orderanalysis,getStaffStatisData } from "@/request/requests";
    import { MessageError,startLoading } from "@/util/util"
    export default {
        name: "page",
        data(){
            return{
                userinfo:{},
                analysisdata:{
                    data:[],
                    time:[]
                },
                staffstatisdata:"",
                orderdatatime:[this.$moment(new Date(new Date().setMonth(new Date().getMonth()-1))).format("YYYY-MM-DD"), this.$moment(new Date()).format("YYYY-MM-DD")],
                orderData:"",
                orderanalysistime:[this.$moment(new Date(new Date().setMonth(new Date().getMonth()-1))).format("YYYY-MM-DD"), this.$moment(new Date()).format("YYYY-MM-DD")],
                statistime:[this.$moment(new Date(new Date().setMonth(new Date().getMonth()-1))).format("YYYY-MM-DD"), this.$moment(new Date()).format("YYYY-MM-DD")],
                time: [],
                data: []
            }
        },
        methods:{
            async getOrderDataInfo() {
                let loading=startLoading()
                let data={
                    starttime:this.orderdatatime[0],
                    endtime:this.orderdatatime[1],
                }
                let res = await getOrderData(data)
                if (res.status == "200") {
                    this.orderData = res.data
                    loading.close()
                } else {
                    MessageError(res.msg)
                    loading.close()
                }
                loading.close()
            },
            getworkData(){
                let myChart = this.$echarts.init(this.$refs.work);
                myChart.setOption(this.work);
            },
            async getAnalysisData() {
                let that=this
                that.data=[]
                that.time=[]
                let data={
                    starttime:this.orderanalysistime[0],
                    endtime:this.orderanalysistime[1],
                }
                let res = await orderanalysis(data)
                if (res.status=="200"){
                    console.log(res.data)
                    res.data.forEach(r=>{
                        that.data.push(r.data)
                        that.time.push(r.time)
                    })
                } else {
                    MessageError(res.msg)
                }
                this.getworkData()
            },
            getStatisData(){
                let myChart = this.$echarts.init(this.$refs.statis);
                myChart.setOption(this.statis);
            },
            async getStaffStatisDataInfo() {
                this.analysisdata.data=[]
                this.analysisdata.time=[]
                let data={
                    starttime:this.statistime[0],
                    endtime:this.statistime[1],
                }
                let res = await getStaffStatisData(data)
                if (res.status=="200"){
                    this.staffstatisdata=res.data
                    res.data.avos.forEach(r=>{
                        this.analysisdata.data.push(r.data)
                        this.analysisdata.time.push(r.time)
                    })
                } else {
                    MessageError(res.msg)
                }
                this.getStatisData()
            },
            getUserInfo(){
                let deadline = sessionStorage.getItem('deadline');
                let userInfo = sessionStorage.getItem("userInfo");
                let token = sessionStorage.getItem('token');
                if(!deadline||!userInfo||!token) {
                    this.removeInfo()
                    MessageError("请先登录！")
                    this.$router.push({ path: "/login" });
                    return false
                }else{
                    let now = new Date().getTime();
                    if (deadline<now){
                        this.removeInfo()
                        MessageError("请先登录！")
                        this.$router.push({ path: "/login" });
                        return false
                    }
                }
                return userInfo;
            },
            removeInfo(){
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("deadline");
                sessionStorage.removeItem("token");
            },
        },
        computed:{
            work(){
                let that =this
                let option = {
                    title: {
                        text: '订单统计(￥)'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['销售额']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '15%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: that.time
                    },
                    yAxis: {
                        type: 'value'
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            xAxisIndex: 0,
                            filterMode: 'empty'
                        }
                    ],
                    series: [
                        {
                            name: '销售额',
                            type: 'line',
                            data: that.data
                        },
                    ]
                };
                return option;
            },
            statis(){
                let that =this
                let option = {
                    title: {
                        text: '销售额统计($)'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['销售额']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '15%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: that.analysisdata.time
                    },
                    yAxis: {
                        type: 'value'
                    },
                    dataZoom: [
                        {
                            type: 'slider',
                            xAxisIndex: 0,
                            filterMode: 'empty'
                        }
                    ],
                    series: [
                        {
                            name: '销售额',
                            type: 'line',
                            data: that.analysisdata.data
                        },
                    ]
                };
                return option;
            },
        },
        mounted(){
            let userInfo=this.getUserInfo()
            if (userInfo){
                this.userinfo=JSON.parse(userInfo)
            }
            if (this.userinfo.usertype==18){
                this.getOrderDataInfo()
                this.getAnalysisData()
            }
            if (this.userinfo.usertype==21){
                this.getStaffStatisDataInfo()
            }
        },
    }
</script>

<style scoped>
    .box-card {
        width: 100%;
    }
    .info-box{
        height: 185px;
        margin: 2px 0;
        display: flex;
    }
    .info-box > div{
        width: 33.333333333333%;
        text-align: center;
        padding: 8px;
    }
    .item{
        margin-top: 1.5em;
        font-size: 18px;
        font-weight: bold;
    }
</style>
